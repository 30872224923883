import { Component, OnInit } from "@angular/core";
import { Demo } from "src/app/shared/interfaces/demo";
import { VendorService } from "../../services/vendor/vendor.service";
import { FormGroup } from "@angular/forms";
import {
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import * as moment from "moment";
import { Plugins } from "@capacitor/core";
import { Vendor } from "../../interfaces/vendor";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
@Component({
  selector: "app-new-demo",
  templateUrl: "./new-demo.component.html",
  styleUrls: ["./new-demo.component.scss"],
})
export class NewDemoComponent implements OnInit {
  public link;
  public loggedVendor: Vendor;
  constructor(
    private vendorService: VendorService,
    public modalController: ModalController,
    private alertController: AlertController,
    private toastController: ToastController
  ) {
    this.vendorService.getLoggedVendor().subscribe((vendor: any) => {
      this.loggedVendor = vendor;
      this.demo.responsavel = vendor.external_id;
      // console.log(vendor);
    });
  }

  ngOnInit() {}

  close() {
    this.modalController.dismiss();
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: "Erro",
      message: message,
      buttons: ["OK"],
    });

    await alert.present();
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
    });
    toast.present();
  }

  validations_form: FormGroup;
  dataReturned: any;

  demo: Demo = {
    nome: "",
    numero: "",
    id: "",
    type: "",
    responsavel: "",
    data: "",
    demolink: "",
  };

  validation_messages = {
    required: [
      { type: "required", message: "Obrigatório" },
      { type: "pattern", message: "Invalido" },
    ],
  };

  async createLink() {
    if (
      this.demo.nome &&
      this.demo.type &&
      this.demo.numero &&
      this.demo.data &&
      this.demo.id
    ) {
      let name = this.demo.nome.split(" ").join("%20");
      const link =
        "https://demo.anota.ai/?name=" +
        name +
        "&type=" +
        this.demo.type +
        "&number=" +
        this.demo.numero +
        "&date=" +
        moment(this.demo.data).format("YYYY/MM/DD") +
        "%20" +
        moment(this.demo.data).format("HH:mm") +
        "&sdr=" +
        this.demo.responsavel +
        "&id_deal=" +
        this.demo.id +
        "&rescheduling=true";

      this.demo.demolink = link;
    } else {
      this.presentAlert("Complete os campos corretamente!");
    }
  }

  copyDemoLink() {
    if (this.demo.demolink) {
      Plugins.Clipboard.write({
        string: this.demo.demolink,
      }).then(
        (res) => this.presentToast("Link copiado com sucesso"),
        (err) => this.presentAlert("Não foi possível copiar o link")
      );
    } else {
      this.presentAlert(
        "É necessário gerar um link para utilizar a função de copiar!"
      );
    }
  }
}
