<ion-header>
  <ion-toolbar color="dark">
    <ion-title>Nova Vídeo Demonstração</ion-title>
  </ion-toolbar>
</ion-header>
<br />
<ion-content>
  <ion-item>
    <ion-icon name="body" slot="start"></ion-icon>
    <ion-label position="floating" color="medium">Nome do Cliente</ion-label>
    <ion-input [(ngModel)]="demo.nome" #nome></ion-input>
  </ion-item>

  <ion-item>
    <ion-icon name="call" slot="start"></ion-icon>
    <ion-label position="floating" color="medium">Número do Cliente</ion-label>
    <ion-input
      required="true"
      type="number"
      minlength="8"
      [(ngModel)]="demo.numero"
    ></ion-input>
  </ion-item>

  <ion-item>
    <ion-icon name="pizza" slot="start"></ion-icon>
    <ion-label position="floating" color="medium">ID do Negócio</ion-label>
    <ion-input
      required="true"
      type="number"
      minlength="4"
      [(ngModel)]="demo.id"
    ></ion-input>
  </ion-item>

  <ion-item>
    <ion-icon name="settings" slot="start"></ion-icon>
    <ion-label position="floating" color="medium">Tipo</ion-label>
    <ion-select [(ngModel)]="demo.type" interface="popover">
      <ion-select-option value="1">Automação</ion-select-option>
      <ion-select-option value="2">Migração</ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item>
    <ion-icon name="calendar" slot="start"></ion-icon>
    <ion-label position="stacked" color="medium">Data limite</ion-label>
    <ion-input
      required="true"
      type="datetime-local"
      max="2099-06-30T16:30"
      [(ngModel)]="demo.data"
    ></ion-input>
  </ion-item>
  <br />

  <div class="ion-text-center">
    <ion-button color="tertiary" style="margin-right: 5px" (click)="close()"
      >Cancelar</ion-button
    >
    <ion-button style="margin-left: 5px" (click)="createLink()"
      >Gerar Link</ion-button
    >
  </div>

  <ion-grid>
    <ion-row>
      <ion-col size="11">
        <ion-item>
          <ion-label position="floating" color="medium"
            >Link para Demonstração</ion-label
          >
          <ion-input type="text" [(ngModel)]="this.demo.demolink" readonly>
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col class="ion-align-self-end" size="1">
        <ion-button
          style="
            --background: transparent;
            color: rgb(60, 52, 78);
            --box-shadow: 0px;
            margin-left: -3px;
          "
          (click)="copyDemoLink()"
        >
          <ion-icon name="copy-outline"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
