import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { AuthService } from "../auth/auth.service";
@Injectable()
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }
  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }
  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}
@Injectable()
export class HTTPListener implements HttpInterceptor {
  private _requests = 0;
  constructor(
    private status: HTTPStatus,
    private router: Router,
    private alertController: AlertController,
    private auth: AuthService
  ) {}

  async presentAlert(header, message) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ["OK"],
    });

    await alert.present();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    ++this._requests;
    this.status.setHttpStatus(true);
    return next.handle(req).pipe(
      map((event) => {
        return event;
      }),
      catchError((error) => {
        switch (error.status) {
          case 0:
            this.presentAlert("Erro", "Falha na requisição");
            break;
          case 400:
            this.presentAlert("Erro", "Requisição inválida");
            break;
          case 401:
            this.auth.logout();
            this.router.navigate(["login"]);
            break;
          case 403:
            this.presentAlert("Erro", "Execução recusada");
            break;
          case 404:
            this.presentAlert("Erro", "Não Encontrado");
            break;
          case 408:
            this.presentAlert("Erro", "Tempo de requisição esgotado");
            break;
          case 413:
            this.presentAlert("Erro", "Este arquivo é muito grande");
            break;
          case 500:
            // this.presentAlert("Erro", "Erro interno do Servidor");
            break;
          case 501:
            this.presentAlert("Erro", "Não implementado");
            break;
          case 502:
            this.presentAlert("Erro", "Bad Gateway");
            break;
          case 503:
            this.presentAlert("Erro", "Serviço indisponível");
            break;
          default:
            // if (this.auth.marketplaceAuthenticationState) {
            //   // this.auth.logout();
            //   console.log("Fechando sessão");
            // }
            if(error.message === "Não encontramos nenhum cardápio com este CNPJ, reveja os dados e tente novamente") return throwError(error);

            if(error?.errors?.length > 0){
              error.message = "";
              for(let i = 0; i < error.errors.length; i++){
                if(error.errors[i].message){
                  error.message += error.errors[i].message + "\n";
                }
              }
            }
            if (error.message) {
              this.presentAlert(
                "Erro",
                error.message
              );
              break;
            } else {
              this.presentAlert(
                "Erro",
                "Tivemos problemas por aqui. Tente novamente mais tarde!"
              );
              break;
            }
          // window.location.reload();
        }
        return throwError(error);
      }),
      finalize(() => {
        --this._requests;
        this.status.setHttpStatus(this._requests > 0);
      })
    );
  }
}
