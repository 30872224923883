import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastController } from "@ionic/angular";
import { Observable } from "rxjs";
import { Menu } from "src/app/shared/interfaces/menu";
import { Vendor } from "src/app/shared/interfaces/vendor";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { SalesService } from "src/app/shared/services/sales/sales.service";
import { VendorService } from "src/app/shared/services/vendor/vendor.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "anotaai-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.scss"],
})
export class MenuComponent implements OnInit {
  public loggedVendor;
  private id_ifood = environment.ID_IFOOD;
  numberOfChargebacks$: Observable<any>;
  menu: Menu[];
  paymentSystem;

  constructor(
    public salesService: SalesService,
    private vendorService: VendorService,
    private authService: AuthService,
    private router: Router,
    private toastController: ToastController
  ) {
    this.vendorService.getLoggedVendor().subscribe((vendor) => {
      this.loggedVendor = vendor;
      // console.log(vendor)
    });
  }

  ngOnInit(): void {
    this.numberOfChargebacks$ = this.salesService.getnumberOfChargebacks();
    this.showPaymentSystem();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(["login"]);
    this.presentToast("Logout realizado com sucesso!");
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  getIsFreelancer() {
    return this.loggedVendor?.role?.tag == "FREE";
  }

  getIsAdmin() {
    return this.loggedVendor?.config?.admin || this.loggedVendor?.is_admin;
  }

  getIsCoordinator() {
    return this.loggedVendor?.is_coordinator;
  }

  getIsIfood(){
    return this.loggedVendor?.organization == this.id_ifood;
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
    });
    toast.present();
  }

  goToDataStudio() {
    window.open(
      "https://datastudio.google.com/u/0/reporting/4b3b44ac-88eb-4079-8300-c63c760e4e5e/page/PjncB",
      "_blank"
    );
  }

  showPaymentSystem() {
    let paymentSystem;
    this.salesService.getPaymentSystemName().subscribe((res: any) => {
      // console.log("response", res)
      this.paymentSystem = res.data.sale_payment_system;
    });
  }
}
