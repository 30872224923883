import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { FiltersService } from "../filters/filters.service";
import { VendorService } from "../vendor/vendor.service";
import { Vendor } from "../../interfaces/vendor";

@Injectable({
  providedIn: "root",
})
export class SalesService {
  public urlNoAuth = environment.BACKEND_URL + "/noauth";
  public url = environment.BACKEND_URL + "/salesauth";
  public freemiumUrl = environment.FREEMIUM_URL + "/noauth/customer-register"
  public pageInfoUrl = environment.PAGE_INFO_URL;

  public totalDocs;
  public sales = [];
  public loggedVendor: Vendor;

  constructor(
    private http: HttpClient,
    private filtersService: FiltersService,
    private vendorService: VendorService
  ) {}

  async listSales(page, searchTerm: string = "", vendorId: string = "", ifood: boolean = false) {
    this.vendorService.getLoggedVendor().subscribe((vendor:any) => {
      
      if(!vendor ) return console.log('no vendor, returning');
      this.loggedVendor = vendor;
      // console.log('vendor: ',this.loggedVendor)
      let limit = 23;
      let selectedFilters = this.filtersService.getSelectedFilters();
      let statusArray = selectedFilters.length == 0 ? null : JSON.stringify(selectedFilters);
       
      let url = `${this.url}/sales/v2/${vendorId}?limit=${limit}&page=${page}&name=${searchTerm}&status=${statusArray}`;
      
      if(ifood){
        const ifoodClosers = ['CE', 'INT', 'OUTS', 'EXP', 'SPI','SPE','Vitrine','Outbound','HIB','KA','HUNT','PJ']
        let closers = ifoodClosers.join('_')
        url = `${this.url}/sales/ifood-sales?limit=${limit}&page=${page}&closer_types=${closers}&name=${searchTerm}`;
      }

      if (this.loggedVendor?.role?.tag == "FREE" && !vendorId) {
        return window.location.href = "/sales";
      }

      this.http.get(url).subscribe((res: any) => {
        if (page == 1) this.sales = [];
        this.totalDocs = res.data.totalDocs;
        this.sales = this.sales.concat(res.data.docs);
        // console.log(this.sales);
      });
    });
  }

  getnumberOfChargebacks() {
    return this.http.get(this.url + "/sales/getchargebacks");
  }

  getSale(saleId) {
    let url = this.url + "/sales/getbyid/" + saleId;
    return this.http.get(url);
  }

  editSale(saleId, editedSale) {
    let url = this.url + "/sales/updatesale/" + saleId;
    return this.http.patch(url, editedSale);
  }

  updatePlan(saleId, planId) {
    let url = this.url + "/sales/updateplan/" + saleId;
    return this.http.patch(url, { plan_id: planId });
  }

  updatePlanSuperLogica(saleId, planId) {
    let url = this.url + "/sales/changeplan/" + saleId;
    return this.http.patch(url, { plan_id: planId });
  }

  updateModules(saleId, modules) {
    let url = this.url + "/sales/updatemodules/" + saleId;
    return this.http.patch(url, { modulesArray: modules });
  }

  updatePreferences(saleId, preferences) {
    let url = this.url + "/sales/updatepreferences/" + saleId;
    return this.http.patch(url, preferences);
  }

  listPlans() {
    let url = this.url + "/sales/listplans/";
    return this.http.get(url);
  }

  listModules() {
    let url = this.url + "/sales/listmodules/";
    return this.http.get(url);
  }

  getSalePreferences(saleId) {
    let url = this.url + "/sales/listpreferences/" + saleId;
    return this.http.get(url);
  }

  newSale(sale) {
    let url = this.url + "/sales";
    return this.http.post(url, sale);
  }
  updateSale(sale) {
    let url = this.url + "/sales/" + sale._id;
    return this.http.put(url, sale);
  }

  cancelBillet(eventSaleId, transactionId, reason) {
    let url = this.url + "/sales/cancelbillet/";
    return this.http.post(
      url + eventSaleId + `?transactionId=${transactionId}`,
      { cancelReason: reason }
    );
  }

  getDetailsTransaction(transactionId) {
    let url = this.url + "/sales/get-transaction";
    return this.http.get(url + `?transaction=${transactionId}`);
  }

  emailVerify(email) {
    const url =  this.url + `/sales/checkemail?email=${email}`;
    return this.http.get(url);
  }

  getCupons(cuponCode){
    let url = this.url + `/cupons/${cuponCode}`
    return this.http.get(url)
  }

  getPaymentSystemName() {
    const url = this.url + "/sales/configuration/getsystem";
    return this.http.get(url);
  }

  changeByEmail(saleId) {
    const url = this.url + `/sales/payment/changebyemail/${saleId}`;
    return this.http.put(url, {});
  }

  changeDiscount(saleId, discount) {
    const url = this.url + `/sales/updatediscountcupon/${saleId}`;
    return this.http.patch(url, {"discount": discount});
  }

  upload(file, saleId){
    const formData = new FormData();
    formData.append('url', file);
    const url = this.url + `/sales/sendfiletomoskit/${saleId}`;
    return this.http.post(url, formData);
  }

  invalidClientRouter(saleId){
    const url = this.url + `/sales/invalidate-client/${saleId}`;
    return this.http.post(url, {});
  }

  getInformations(dealId){
    let url = this.url + `/sales/get-deal-data/${dealId}`
    return this.http.get(url);
  }

  deactivateClient(saleId){
    let url = this.url + `/sales/deactivate-client/${saleId}`
    return this.http.post(url, {});
  }

  setSaleWon(saleId){
    let url = this.url + `/sales/admin-setwon-button/${saleId}`
    return this.http.post(url, {});
  }

  updateSaleField(saleId, field, value){
    let url = this.url + `/sales/update-only-sale/${saleId}`
    return this.http.put(url, {[field]: value});
  }

  getSalesByDealId(dealId){
    let url = this.url + `/sales/get-sales-by-deal-id/${dealId}`
    return this.http.get(url);
  }


  getOnboardingData(page_id){
    let url = `${this.freemiumUrl}/${page_id}`
    return this.http.get(url);
  }

  getPageDetails(query){
    let url = `${this.url}/sales/get-establishment-informations?name=${query}`
    return this.http.get(url);
  }

  getPageSalesDetails(page_id){
    let url = `${this.url}/sales/get-amount-orders/${page_id}`
    return this.http.get(url);
  }

  assignVendor(saleId,vendorExternalId){
    let url = `${this.url}/sales/assign-vendor-to-sale/${saleId}`
    return this.http.post(url, {vendor: vendorExternalId});
  }

  getSignedAddons(id):any{
    let url =`${this.url}/sales/get-signature-module/${id}`
    return this.http.get(url);
  }
  
  getAddons(): any{
    let url =`${this.url}/sales/get-modules`
    return this.http.get(url);
  }

}