import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { tap, catchError } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AlertController } from "@ionic/angular";

const TOKEN_KEY = "access_token";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public url = environment.BACKEND_URL + "/noauth/vendor/login";
  public authenticationState: boolean = false;
  private vendorId;
  private _token;

  constructor(
    private http: HttpClient,
    private helper: JwtHelperService,
    private alertController: AlertController
  ) {}

  async presentAlert(header, message) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ["OK"],
    });

    await alert.present();
  }

  getJwtToken(): any {
    if (this._token == undefined || this._token == "") {
      this._token = localStorage.getItem(TOKEN_KEY);
    }
    return this._token;
  }

  checkToken(): Promise<boolean> {
    return new Promise((resolve) => {
      var token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        let decoded = this.helper.decodeToken(token);
        let isExpired = this.helper.isTokenExpired(token);
        if (!isExpired) {
          this.authenticationState = true;
          this.vendorId = decoded;
          resolve(this.authenticationState);
        } else {
          this.authenticationState = false;
          localStorage.removeItem(TOKEN_KEY);
          resolve(this.authenticationState);
        }
      } else {
        this.authenticationState = false;
        resolve(this.authenticationState);
      }
    });
  }

  login(credentials) {
    return this.http.post(this.url, credentials).pipe(
      tap((res: any) => {
        // console.log(res);
        if (res.success) {
          this._token = res["access_token"];
          localStorage.setItem(TOKEN_KEY, res["access_token"]);
          // console.log(localStorage.getItem(TOKEN_KEY));
          this.vendorId = this.helper.decodeToken(res["access_token"]);
          this.authenticationState = true;
        }
      }),
      catchError((err) => {
        throw new Error();
      })
    );
  }

  logout() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem("vendor");
    this.authenticationState = false;
  }

  isAuthenticated(): Promise<boolean> {
    return this.checkToken();
  }

  isLoggedIn(): boolean {
    return localStorage.getItem("access_token") != null;
  }
}
