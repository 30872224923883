import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class MainGuard implements CanActivate {
  constructor(public auth: AuthService, private router: Router) {}

  canActivate(): Promise<boolean> {
    this.auth.checkToken().then((res) => {
      if (!res) {
        this.router.navigate(["login"]);
      }
    });
    return this.auth.checkToken();
  }
}
