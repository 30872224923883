<ion-list *ngIf="isLoggedIn() && loggedVendor" id="inbox-list">
    <div>
        <span class="system-name">{{ paymentSystem }}</span>
        <ion-list-header>Dashboard Comercial</ion-list-header>
        <ion-note>
            {{ loggedVendor?.name }} • Anota AI</ion-note>
        <!-- <ion-menu-toggle auto-hide="false" class="pointer" *ngIf="!getIsFreelancer()">
            <ion-item routerDirection="root" routerLink="/" lines="none" detail="false">
                <ion-icon slot="start" ios="pie-chart" md="pie-chart"></ion-icon>
                <ion-label>Análises</ion-label>
            </ion-item>
        </ion-menu-toggle> -->
        <!-- <ion-menu-toggle auto-hide="false" class="pointer" *ngIf="!getIsFreelancer()">
            <ion-item routerDirection="root" routerLink="/demo" lines="none" detail="false">
                <ion-icon slot="start" ios="play-outline" md="play-outline"></ion-icon>
                <ion-label>Demonstrações</ion-label>
            </ion-item>
        </ion-menu-toggle> -->
        <ion-menu-toggle auto-hide="false" class="pointer">
            <ion-item routerDirection="root" routerLink="/sales" lines="none" detail="false">
                <ion-icon slot="start" ios="cash" md="cash"></ion-icon>
                <ion-label>Vendas</ion-label>
            </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false" class="pointer">
            <ion-item routerDirection="root" routerLink="/freelancers" lines="none" detail="false">
                <ion-icon slot="start" ios="call-outline" md="call-outline"></ion-icon>
                <ion-label>Oportunidades</ion-label>
            </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false" class="pointer">
            <ion-item routerDirection="root" routerLink="/activations" lines="none" detail="false">
                <ion-icon slot="start" ios="build-outline" md="build-outline"></ion-icon>
                <ion-label>Ativações</ion-label>
            </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false" class="pointer" *ngIf="getIsAdmin() || getIsCoordinator()">
            <ion-item routerDirection="root" routerLink="/management-teams" lines="none" detail="false">
                <ion-icon slot="start" ios="people-circle-outline" md="people-circle-outline"></ion-icon>
                <ion-label>Equipes</ion-label>
            </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false" class="pointer" *ngIf="!getIsFreelancer()" (click)="goToDataStudio()">
            <ion-item lines="none" detail="false">
                <ion-icon slot="start" ios="analytics-outline" md="analytics-outline"></ion-icon>
                <ion-label>Data Studio</ion-label>
                <ion-icon slot="end" ios="open-outline" md="open-outline" style="font-size: 18px;"></ion-icon>
            </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false" class="pointer" *ngIf="getIsAdmin()">
            <ion-item routerDirection="root" routerLink="/admin" lines="none" detail="false">
                <ion-icon slot="start" ios="star" md="star"></ion-icon>
                <ion-label>Admin</ion-label>
            </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false" class="pointer">
            <ion-item (click)="logout()" lines="none" detail="false">
                <ion-icon slot="start" ios="log-out" md="log-out"></ion-icon>
                <ion-label>Logout</ion-label>
            </ion-item>
        </ion-menu-toggle>
    </div>
</ion-list>
<!-- <ion-badge color="danger" *ngIf="
          p.title == 'Vendas' &&
          (numberOfChargebacks$ | async) as numberOfChargebacks
        ">
                {{ numberOfChargebacks.data }} chargebacks
            </ion-badge> -->