<ion-app>
  <!-- *ngIf="innerWidth > 1120" -->
  <ion-split-pane contentId="main-content" >
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <anotaai-menu></anotaai-menu>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
  <!-- *ngIf="innerWidth <= 1120" class="dev" -->
  <div >
  </div>
  <app-loading></app-loading>
</ion-app>
