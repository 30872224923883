import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { CommonModule } from "@angular/common";
import { NewDemoComponent } from "./shared/modals/new-demo/new-demo.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HTTPListener,
  HTTPStatus,
} from "./shared/services/interceptor/interceptor.service";
const RxJS_Services = [HTTPListener, HTTPStatus];
import { JwtModule } from "@auth0/angular-jwt";
import { TokenInterceptorService } from "./shared/services/token-interceptor/token-interceptor.service";

import { AngularFirestoreModule } from "angularfire2/firestore";
import { AngularFireModule } from "@angular/fire";
import { firebaseConfig } from "../config/firebase.config";
import { MenuModule } from "./pages/dashboard/menu/menu.module";
import { LoadingModule } from './shared/components/loading/loading.module';
// console.log(firebaseConfig);
@NgModule({
  declarations: [AppComponent, NewDemoComponent ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    LoadingModule,
    ReactiveFormsModule,
    MenuModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem("access_token");
        },
      },
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
