import { Injectable } from '@angular/core';
import { Filter } from '../../interfaces/filter';

@Injectable({
    providedIn: "root",
})
export class FiltersService {

    filters: Filter[];
    
    constructor() {
        this.filters = [
            { id: 5, name: 'Chargeback', selected: false },
            { id: 1, name: 'Venda criada', selected: false },
            { id: 2, name: 'Em processamento', selected: false },
            { id: 3, name: 'Pago', selected: false },
            { id: 4, name: 'Não pago', selected: false },
            { id: 0, name: 'Período Trial', selected: false },
        ]
    }

    getFilters() {
        return this.filters;
    }

    getSelectedFilters(): number[] {

        let mapedSelectedFilter: number[];
        let selectedFilters = this.filters
            .filter(filter => {
                if (filter.selected) {
                    return filter;
                }
            });
        mapedSelectedFilter = selectedFilters.map(filter => {
            return filter.id;
        });

        return mapedSelectedFilter;
    }

    setFilters(filters: Filter[]) {
        this.filters = filters;
    }
    
}