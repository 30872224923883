import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [
        MenuComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        IonicModule
    ], 
    exports: [
        MenuComponent
    ]
})
export class MenuModule {}