import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Vendor } from "../../interfaces/vendor";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class VendorService {
  public url = environment.BACKEND_URL + "/salesauth";
  public vendorsUrl = this.url + "/vendor";
  public vendors = [];
  public loggedVendor = new BehaviorSubject<Vendor>(undefined);

  constructor(
    private http: HttpClient,
  ) {}

  setLoggedVendor() {
    let url = this.url + "/vendor/getvendor";

    if(localStorage.getItem("vendor")){
      this.loggedVendor.next(JSON.parse(localStorage.getItem("vendor")));
      return;
    }


    if (localStorage.getItem("access_token"))
      return this.http.get(url).subscribe(
        (res: any) => {
          if (res.success) {
            this.loggedVendor.next(res.data);
            localStorage.setItem("vendor", JSON.stringify(res.data));
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getLoggedVendor(): Observable<Vendor> {
    return this.loggedVendor.asObservable();
  }

  getVendors(page, searchInput = '') {
    let limit = 23;
    let url = `${this.url}/vendor/getvendors?limit=${limit}&page=${page}&search=${searchInput}`

    return this.http.get(url)
  }

  filterTeamByRole(tag) {
    let url = `${this.url}/vendor/getvendors?role=${tag}`

    return this.http.get(url)
  }

  filterTeamByOnboarding(onboarding) {
    let url = `${this.url}/vendor/getvendors?onboarding=${onboarding}`

    return this.http.get(url)
  }

  createVendor(newVendor) {
    let url = this.url + "/vendor";
    return this.http.post(url, newVendor);
  }

  updatePermissions(vendorId, permission) {
    let url = this.url + "/vendor/updateconfig/" + vendorId;
    return this.http.patch(url, permission);
  }

  updateStatus(vendorId, status: boolean) {
    let url = this.url + "/vendor/updatestatus/" + vendorId;
    return this.http.patch(url, { status: status });
  }

  checkVendorSubtag(sub_tag: any, tag: any) {
    let url = this.url + "/vendor/checksubtag?tag="+tag+"&subtag="+sub_tag;
    return this.http.get(url);
  }

  editVendor(vendor: any) {
    let url = this.url + "/vendor/";
    return this.http.put(url, vendor);
  }

  searchVendors(page, searchInput){
    let limit = 23;
    let url = `${this.url}/vendor/getvendors?limit=${limit}&page=${page}&search=${searchInput}`

    return this.http.get(url);
  }

  checkVendorExternalId(external_id: any):any {
    let url = this.url + "/moskit/get-user/" + external_id;
    return this.http.get(url);
  }

  getVendorsV2(page,limit,searchInput = ''){
    let skip = (page - 1) * limit;
    let url = this.vendorsUrl + `/vendors?limit=${limit}&skip=${skip}`;
    if(searchInput) url += `&name=${searchInput}`;
    return this.http.get(url);
  }

  createVendorV2(vendor){
    let url = this.vendorsUrl + "/vendors";
    return this.http.post(url, vendor);
  }

  updateVendorV2(vendor: Partial<Vendor>){
    let url = this.vendorsUrl + "/vendors/" + vendor._id;
    return this.http.put(url, vendor);
  }

  getCoordinators(page,limit,searchInput = ''){
    let skip = (page - 1) * limit;
    let url = this.vendorsUrl + `/coordinators?limit=${limit}&skip=${skip}`;
    if(searchInput) url += `&name=${searchInput}`;
    return this.http.get(url);
  }

  getTeamByCoordinatorById(coordinator_id){
    let url = this.vendorsUrl + `/team/fetch-by-coordinator-id/${coordinator_id}`;
    return this.http.get(url);
  }

  getAreaValues(orgId){
    let url = this.vendorsUrl + `/area`;
    return this.http.get(url);
  }

  getVendorById(vendorId):any{
    let url = this.vendorsUrl + "/vendors/" + vendorId;
    return this.http.get(url).toPromise();
  }

  getPipelines(){
    let url = this.vendorsUrl + "/pipeline";
    return this.http.get(url);
  }

}
