import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate {
  constructor(public auth: AuthService, private router: Router) {}

  canActivate(): Promise<boolean> {
    this.auth.checkToken().then((res) => {
      if (res) {
        this.router.navigate(["/"]);
      }
    });
    return this.auth.checkToken().then((res) => !res);
  }
}
