// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    BACKEND_URL: "https://staging-ssanotaai-back.anota-ai.com",
    VENDORS_API_URL: "https://staging-vendors-manager-api.anota-ai.com",
    // BACKEND_URL: "http://localhost:3000",
    REPORTS_URL: "https://staging-reports-anotaai.herokuapp.com",
    CRM_URL: 'https://staging-crm-services.anota-ai.com',
    REPORTS_TOKEN:
    "eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1ZGIxZTEzMjBhMGMyOTM5MDA1MjUzMDUiLCJpZHBhZ2UiOiI1Y2RjMmE1Yzc1NzEyMzAwMjRlNDk3ZjEifQ.7y5LtWXc5Rxbg6Z7x6_iNtWAEuGnKdJc7qnXnarYh7U",
    MOSKIT_MIDDLEWARE_URL: "https://staging-inbound.anota-ai.com",
    MOSKIT_MIDDLEWARE_KEY: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb25lY3QiOiJBbm90YSBBSSBlIE1hdXRpYyIsImlhdCI6MTU4NDcyNDg1NH0.h73R3xwzH7Ie_u2DWvJ9axW9z3II0ETbzoBE5d8CsqU",
    FREEMIUM_URL: "https://staging-freemium-api.anota-ai.com",
    MENU_URL: "https://staging-cardapio-digital.anota-ai.com/loja/",
    REGISTER_CREDENCIALS: "https://staging-register-frontend-beta.anota-ai.com",
    SUPERUSER_EMAIL:["naoadmin@anota.ai"],
    PAGE_INFO_URL: "https://vsrf4vjky3.execute-api.us-east-1.amazonaws.com/prod/orders-profile/v1/",
    ONBOARDING_URL: "https://staging-onboarding-register-frontend.anota-ai.com/home/",
    SUPERLOGICA_URL: "https://anotaai-001.superlogica.net/clients/financeiro/sacados/id/",
    ADMIN_URL: 'https://preprod-admin-front.anota-ai.com',
    MOSKIT_URL: "https://app.moskitcrm.com/?/deal/",
    MOSKIT_OPTIONS:[{
        "id": 307781,
        "label": "Clonagem Cardapio",
        "checked": false
    },
    {
        "id": 307782,
        "label": "Configuração de integrações",
        "checked": false
    },
    {
        "id": 307783,
        "label": "Configuração da impressora",
        "checked": false
    },
    {
        "id": 307784,
        "label": "Configuração de pix automatizado",
        "checked": false
    },
    {
        "id": 307785,
        "label": "Edição de cardapio",
        "checked": false
    },
    {
        "id": 307786,
        "label": "Formas de pagamento",
        "checked": false
    },
    {
        "id": 307787,
        "label": "Horarios de funcionamento",
        "checked": false
    },
    {
        "id": 307788,
        "label": "Instalaçao de software",
        "checked": false
    },
    {
        "id": 307789,
        "label": "Tipo de atendimento",
        "checked": false
    },
    {
        "id": 307790,
        "label": "Taxa de entrega",
        "checked": false
    }],
    ID_IFOOD: "65f1e3cd124490d820feb8c8",
    COMERCIAL_AREA:"65f08cda18638794da3e7937",
    WAITER_AREA: "66a2a9ec3675f47c54526047",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
