import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { MainGuard } from "./shared/guards/main/main.guard";
import { LoginGuard } from "./shared/guards/login/login.guard";
import { AdminGuard } from "./shared/guards/admin/admin.guard";
import { TeamComponent } from "./pages/dashboard/management-teams/team/team.component";

const routes: Routes = [
  // {
  //   path: "",
  //   loadChildren: () =>
  //     import("./pages/dashboard/analysis/analysis.module").then(
  //       (m) => m.AnalysisPageModule
  //     ),
  //   canActivate: [MainGuard],
  // },
  {
    path: "freelancers",
    loadChildren: () =>
      import("./pages/dashboard/freelancers/freelancers.module").then(
        (m) => m.FreelancersPageModule
      ),
    canActivate: [MainGuard],
  },
  {
    path: "freelancers-team",
    loadChildren: () =>
      import(
        "./pages/dashboard/admin/freelancers-team/freelancers-team.module"
      ).then((m) => m.FreelancersTeamPageModule),
    canActivate: [MainGuard],
  },
  {
    path: "demo",
    loadChildren: () =>
      import("./pages/dashboard/demo/demo.module").then(
        (m) => m.DemoPageModule
      ),
    canActivate: [MainGuard],
  },
  {
    path: "sales",
    loadChildren: () =>
      import("./pages/dashboard/sales/sales.module").then(
        (m) => m.SalesPageModule
      ),
    canActivate: [MainGuard],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
    canActivate: [LoginGuard],
  },
  {
    path: "sale-details/:id",
    loadChildren: () =>
      import("./pages/dashboard/sales/sale-details/sale-details.module").then(
        (m) => m.SaleDetailsPageModule
      ),
    canActivate: [MainGuard],
  },
  {
    path: "new-sale",
    loadChildren: () =>
      import("./pages/dashboard/sales/new-sale/new-sale.module").then(
        (m) => m.NewSalePageModule
      ),
    canActivate: [MainGuard],
  },
  {
    path: "edit-sale/:id",
    loadChildren: () =>
      import("./pages/dashboard/sales/new-sale/new-sale.module").then(
        (m) => m.NewSalePageModule
      ),
    canActivate: [MainGuard],
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./pages/dashboard/admin/admin.module").then(
        (m) => m.AdminPageModule
      ),
    canActivate: [AdminGuard],
  },
  {
    path: "freelancers",
    loadChildren: () =>
      import("./pages/dashboard/freelancers/freelancers.module").then(
        (m) => m.FreelancersPageModule
      ),
  },
  {
    path: 'activations',
    loadChildren: () =>
      import('./pages/dashboard/activations/activations.module').then(
        m => m.ActivationsPageModule)
  },
  {
    path: 'management-teams',
    loadChildren: () =>
      import('./pages/dashboard/management-teams/management-teams.module').then(
        m => m.ManagementTeamsModule)
  },
  {
    path: "",
    redirectTo: "sales",
    pathMatch: "full",
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
