import { Component, HostListener, OnInit } from "@angular/core";

import { ToastController } from "@ionic/angular";
import { Router } from "@angular/router";
import { HTTPStatus } from "./shared/services/interceptor/interceptor.service";
import { AuthService } from "./shared/services/auth/auth.service";
import { VendorService } from "./shared/services/vendor/vendor.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public innerWidth;
  auth: boolean;

  constructor(
    private router: Router,
    private httpStatus: HTTPStatus,
    private toastController: ToastController,
    private authService: AuthService,
    public vendorService: VendorService,
  ) {
    this.innerWidth = window.innerWidth;
    this.vendorService.setLoggedVendor();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    // if (localStorage.getItem("access_token") != null) {
    //   console.log("Tem Token");
    // } else {
    //   ("Sem token");
    // }
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
    });
    toast.present();
  }
}
