import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Vendor } from "../../interfaces/vendor";
import { AuthService } from "../../services/auth/auth.service";
import { VendorService } from "../../services/vendor/vendor.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  public loggedVendor: Vendor;
  constructor(private vendor: VendorService, private router: Router) {
    this.vendor.getLoggedVendor().subscribe((vendor) => {
      this.loggedVendor = vendor;
    });
  }

  canActivate() {
    if (this.loggedVendor?.config?.admin) {
      return true;
    } else {
      this.router.navigate(["/"]);
      return false;
    }
  }
}
